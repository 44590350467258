.top {
  display: flex;
}
.clicker {
  display: flex;
  cursor: pointer;
}
.icon {
  margin-right: 10px;
}
