.input {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  border: 1px solid #dddddd;
  font-size: 16px;
  background-color: white;
  &.error {
    border-color: #ff7a7c;
  }
  &Focused {
    background-color: #eef4ff;
    border-color: #7abd43;
    box-shadow: 0 0 4px 1px rgba(132, 190, 63, 0.58);
    &.error {
      box-shadow: 0 0 4px 1px rgba(255, 122, 124, 0.58);
    }
  }
  input {
    min-width: 50px;
    // min-width:100px;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: inherit;
    border: none;
    &::placeholder {
      color: #9b9b9b;
    }
  }
  .eyeIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
  }
  .units {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    color: #9b9b9b;
  }
}
.plug {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
}
.upDown {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    flex: 1;
    padding: 2px 10px;
    cursor: pointer;
  }
}