.top {
  padding: 30px;
}
.buttons {
  margin: 300px -5px -5px;
  display: flex;
  flex-wrap: wrap;
}
.button {
  margin: 5px;
  height: 40px;
  padding: 0 10px; 
  color: #222629;
  font-size: 16px;
  background-color: white;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.bottom {
  flex: 1;
  background-color: white;
}
.productDetails {
  margin: 0 30px;
}
.details {
  width: 92px;
  height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  color: #7abd43;
  font-size: 14px;
  border-color: #7abd43;
}