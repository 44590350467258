.deposits {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.addDeposit {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 242px;
  height: 40px;
  color: #222629;
  background-color: white;
  font-size: 14px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.carousel {
  position: absolute;
  left: 30px;
  top: 90px;
  width: 610px;
}
.depositTile {
  width: 340px;
}
.suggestionTile {
  width: 522px;
  padding-left: 20px;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
