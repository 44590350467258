.wrapper {
  width: 400px;
  .content {
    display: flex;
    justify-content: space-between;
    .left {
      width: 220px;
    }
    .right {
      width: 160px;
    }
  }
}
