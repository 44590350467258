.wrapper {
  height: 100%;
  padding: 30px;
  background-color: white;
}
.content {
  margin: 30px auto 0;
  width: 500px;
}
.subtitle {
  margin-bottom: 10px;
  color: #222629;
  font-weight: 700;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.text {
  margin-bottom: 30px;
  font-size: 12px;
  color: #9b9b9b;
}
.link {
  color: #241f5a;
}
.commisionText {
  height: 56px;
  line-height: 56px;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: white;
  background-color: #9b9b9b;
  border-radius: 50%;
  cursor: pointer;
}
.error {
  margin-bottom: 20px;
}
.globalError {
  margin-bottom: 30px;
}
// -----------------------------
.input {
  width:100%;
}