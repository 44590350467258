.wrapper {
  height: 50px;
  cursor: pointer;
  a, div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px 0 40px;
    font-size: 16px;
    color: #606060;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: left center;
  }
}