.productDetails {
  padding: 40px 0;
  background-color: white;
}
.rows {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 20px 4%;
  font-size: 14px;
}
.label {
  height: 24px;
  line-height: 24px;
  color: #606060;
}
.value {
  height: 24px;
  line-height: 24px;
  color: #030303;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.buttonBlock {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.buttonTemplate {
  font-family: Helvetica;
  font-weight:bold;
  color: #241f5a;
  border:1px solid #241f5a;
  height: 35px;
  width: 202px;
  padding-left:5px;
  padding-right:5px;
}
.button {
  font-family: Helvetica;
  font-weight:bold;
  color: #241f5a;
  border:1px solid #241f5a;
  width: 242px;
  height: 35px;
  margin-left: 10px;
  padding-left:5px;
  padding-right:5px;
}
.buttonBlue {
  font-family: Helvetica;
  font-weight:bold;
  color:#ffffff;
  background-color: #241F5A;
  width: 218px;
  height: 35px;
  margin-left: 10px;
  padding-left:5px;
  padding-right:5px;
}
.recieptBlock{
  border-top: 1px solid #f2f2f2;
  margin-top: 15px;
  padding-top:25px;
}