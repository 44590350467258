.categoriesWrapper {
  display:flex;
  flex-wrap:wrap;
}

.category {
  display:flex;
  align-items:center;
  width: 218px;
  height: 60px;
  margin:15px;
  padding-left:15px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.icon {
  width:25px;
  height:25px;
  margin-right:15px;
}

.form {
  padding: 30px;
  background-color: white;
}

.row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.text {
  margin-bottom: 20px;
  font-size: 14px;
  color: #606060;
}

.searches {
  display: flex;
  align-items: center;
  border: 1px solid #dddddd;
}

.billerWrapper {
  display: flex;
  flex-wrap: wrap;
}
.biller {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 140px;
  margin: 15px 18px;
  cursor: pointer;
}
.imageWrapper {
  display: flex;
  justify-content: center;
  margin-bottom:10px;
}
.billerIcon {
  width: 90px;
  height: 90px;
  transform: scale(0.9);
  transition: transform 0.5s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
}
.billerTitle {
  font-size: 14px;
  text-align: center;
}
.spinner {
  margin: 50px auto;
}
