.slider {
  margin-left: 40px;
  margin-right: 40px;
}
.arrow {
  background-color: rgba(155, 155, 155, 0.46) !important;
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  &::before {
    content: "" !important;
  }
  &:hover {
    background-color: #241f5a !important;
  }
  &Prev {
    left: -40px !important;
  }
  &Next {
    right: -40px !important;
  }
}
.arrowIcon {
  height: 20px;
  &Prev {
    margin-left: 4px;
    margin-top: 6px;
  }
  &Next {
    margin-left: 8px;
    margin-top: 6px;
    transform: rotate(180deg);
  }
}
.slideWrapper {
  padding: 10px;
  &Transparent {
    opacity: 0.5;
  }
  & > * {
    margin: auto;
  }
}
