.financialAssistant {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.top {
    padding: 30px;
}
.bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.inputMonthBlock {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
}
.label {
    font-size: 14px;
    color: #606060;
    margin-bottom: 5px;
    margin-left: 25px;
}
.errorWrapper {
    margin-top: 25px;
    padding: 0 25px;
}
.infoBlock {
    display: flex;
    justify-content: space-around;
    width: 100%;
    min-height: 250px;
    margin-top: 60px;
    padding: 0 25px 0 25px;
}
.pieChart {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
}
.defaultPieChart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    border: 0.5px solid #979797;
    border-radius: 50%;
    .text {
        display: block;
        text-align: center;
        color: #979797;
        width: 120px;
        font-size: 12px;
    }
}
.outcomes,
.incomes {
    display: flex;
    flex-direction: column;
    width: 80%;
}
.title {
    display: flex;
    justify-content: space-between;
    color: #222629;
    font-size: 16px;
    font-weight: bold;
}
.amount {
    font-size: 20px;
    span {
        font-size: 12px;
    }
}
.subtitle {
    font-size: 14px;
    font-weight: bold;
    color: #9b9b9b;
    margin: 15px 0 15px 0;
}
.outlayCategories,
.incomeCategories {
    display: flex;
    flex-direction: column;
}
.category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 12px 0;
    cursor: pointer;

    span {
        display: block;
        span {
            color: #9b9b9b;
        }
    }
}
.categoryIcon {
    width: 26px;
    height: 26px;
    margin-right: 10px;
}
.categoryName {
    display: flex;
    width: 38%;
    margin-left: 10px;
}
.localAmount {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 35%;
    span {
        display: block;
    }
}
.currency {
    color: #9b9b9b;
    font-size: 9px;
    margin-left: 3px;
}
