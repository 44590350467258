.operationResult {
  width: 400px;
  text-align: center;
}
.title {
  margin: 30px 0;
}
.text {
  margin: 30px 0;
  text-align: center;
  font-size: 14px;
  color: black;
}

.pin {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  > div {
    margin: 8px;
    background-color: lightgray;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &.active {
      background-color: gray;
    }
  }
}

.pinPad {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 50px;

  > div {
    font-size: 24px;
    cursor: pointer;
    display: flex;
    flex: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 15px 25px;
  }
}

