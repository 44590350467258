.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:16px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
}