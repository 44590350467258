.changeCategoriesWrapper {
    width:800px;
}
.subTitle {
    color: #222629;
    font-size: 18px;
    font-weight: bold;
    font-family: Helvetica;
}
.categoriesWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    // width:80%;
}
.categories {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-bottom: 15px;
}
.imageAndNameBlock {
    display: flex;
    align-items: center;
}
.categoryIcon {
    margin-right: 15px;
}
.categoryImage {
    width: 32px;
    height: 32px;
    margin-right: 15px;
}
.categoryName {
    color:#222629;
    font-size: 16px;
    font-family: SFProText;
}
.radio {
    color: green;
    width: 20px;
    height: 20px;
    margin-right: 45px;
    cursor:pointer;
}
.buttons {
    display: flex;
    justify-content: center;
    margin-top:15px;
}
.cancelButton {
    width: 216px;
    height: 44px;
    color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
    margin-right: 10px;
}
.saveChanesButton {
    width: 216px;
    height: 44px;
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
    margin-left: 10px;

}
