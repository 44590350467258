.home {
  margin: auto;
  display: flex;
  flex-direction: column;

  width: 1024px;
  min-height: 100vh;
  background-color: #F8F8F8;


  .content {
    margin-top: 2px;
    flex: 1;
    // height: 100vh;
    display: flex;
    // align-items: flex-start;
    .sidebar {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 272px;
      background-color: white;
    }
    .main {
      margin-left: 2px;
      width: 750px;
    }
  }


}
.button {
  position: fixed;
  bottom: 60px;
  left: calc(50% + 472px);
  width: 40px;
  height: 40px;
  background-color: #241F5A;
  color: white;
  font-weight: bold;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.exchange {
  margin: 32px;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contractsError {
  margin: 30px;
}