.templateTile {
  width: 280px;
  // height: 130px;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  // overflow: hidden;
}
.top {
  height: 65px;
  display: flex;
  background-color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
}
.regularPaymentIcon {
  //  margin-top: -5px;
  margin-left: -10px;
}
.title {
  margin-top: 15px;
  width: 192px;
  font-family: Helvetica;
  font-size: 18px;
  color: #222629;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.description {
  margin-top: 5px;
  width: 192px;
  font-size: 12px;
  color: #9b9b9b;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.openIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 10px;
  background-color: #fafafa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.amount {
  font-size: 24px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.currency {
  font-weight: normal;
  font-size: 16px;
}
.button {
  // width: 92px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  color: #7abd43;
  font-size: 14px;
  border-color: #7abd43;
}
.options {
  z-index: 1;
  position: absolute;
  // top: 50%;
  width: 100%;
  // box-shadow: 3px 3px 8px 0 rgba(12, 20, 64, 0.25);
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}
.option {
  display: flex;
  align-items: center;
  color: #222629;
  font-family: Helvetica;
  width: 100%;
  height: 48px;
  padding: 0 10px;
  background-color: white;
  border-top: 1px solid #f2f2f2;
  // &:last-child {
  //   border-bottom: none;
  // }
  &:hover {
    background-color: #e9f2da;
  }
  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
.label {
  font-size: 14px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
