.tabs {
  display: flex;
  padding: 0 30px;
}
.tab {
  margin-right: 30px;
  display: flex;
  align-items: center;
  height: 48px;
  color: #222629;
  font-size: 14px;
  font-weight: 700;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &Active {
    border-bottom-color: #241f5a;
    color: #241f5a;
  }
}
