.profile {
  position: relative;
  padding: 30px;
  min-height: 100%;
}
.toggleWrapper {
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
}
.carouselWrapper {
  height: 300px;
}
.carousel {
  margin-top: 20px;
  margin-bottom: 120px;
}
.up {
  z-index: 1;
}
.divider {
  border-bottom: 1px dotted #c2c2c2;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
