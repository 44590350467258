.cardTile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  height: 180px;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-image: url("../../../assets/icons/card-bg-green.jpg");
  background-position: center;
  background-size: cover;
  .row {
    display: flex;
    height: 32px;
  }
  .rowTop {
    display:flex;
    justify-content:space-between;
    align-items: flex-end;
    color: white;
  }
  .amount {
    font-size: 26px;
  }
  .currency {
    position:relative;
    font-size: 10px;
    left:5px;
    top:4px;
    // z-index:2;
  }
  .rowMiddle {
    //justify-content: space-between;
    //align-items: center;
    flex-direction: column;
  }
  .cvv {
    padding-top: 4px;
    color: black;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .number {
    color: black;
    font-size: 18px;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .copyIcon {
    margin-bottom:10px;
    margin-right:9px;
    cursor: pointer;
  }

  .rowBottom {
    justify-content: space-between;
    align-items: center;
    & > div:last-child {
      display: flex;
    }
  }
  .owner {
    margin-right: 20px;
    color: black;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .date {
    margin-right: 20px;
    color: black;
    font-size: 14px;
  }
  .settingsIconWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom:5px;
    margin-right:-10px;
    cursor: pointer;
  }
}
.accountTile {
  width: 280px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  .top {
    height: 65px;
    display: flex;
    background-color: #ffffff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .description {
    width: calc(100% - 36px);
    padding-left: 10px;
  }
  .name {
    margin-top: 15px;
    font-size: 18px;
    color: #222629;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .text {
    margin-top: 5px;
    font-size: 12px;
    color: #9b9b9b;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .openIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  .options {
    width: 100%;
    cursor: pointer;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
  }
  .option {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 10px;
    background-color: white;
    border-top: 1px solid #f2f2f2;
    &:hover {
      background-color: #e9f2da;
    }
    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
  .optionIconWrapper {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .label {
    font-size: 14px;
    color: #606060;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
