.wrapper {
  width: 208px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  padding-bottom: 10px;
}

.footer {
  padding-top: 10px;
}

.exchange {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  & > div {
    &:nth-child(3n + 1) {
      color: #222629;
      font-size: 14px;
      font-weight: bold;
    }
    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      max-width: 55px;
      color: #121212;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:nth-child(2),
    &:nth-child(3) {
      color: #9b9b9b;
      font-size: 12px;
    }
  }
}

.button {
  width: 100%;
  height: 48px;
  font-weight: bold;
  &Back {
    margin-right: 30px;
    color: #241f5a;
    text-decoration: underline;
  }
  &Submit {
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
  }
}
