.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.languageSwitch {
  position: absolute;
  right: 20px;
  top: 15px;
}
.title {
  margin: 30px auto;
}
.input,
.error {
  margin-bottom: 15px;
}
.button {
  margin: 10px auto 15px;
  width: 270px;
  height: 48px;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
.row {
  height: 20px;
}
.link {
  margin: auto;
  font-size: 14px;
  color: #241f5a;
}
