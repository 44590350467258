.top {
  height: 340px;
  padding: 30px;
}
.bottom {
  flex: 1;
  background-color: white;
}
.productDetails {
  margin: 0 30px;
}
.details {
     width: 92px;
    height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    color: #7abd43;
    font-size: 14px;
    border-color: #7abd43;
}