.operationResult {
  width: 400px;
  text-align: center;
}
.title {
  margin: 30px 0;
}
.text {
  margin: 30px 0;
  text-align: center;
  font-size: 14px;
  color: #9b9b9b;
}
.button {
  margin: auto;
  width: 216px;
  height: 44px;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
.buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px 0 0 0;
}
.buttonCancel {
  margin-right: 20px;
  width: 190px;
  height: 44px;
  color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
