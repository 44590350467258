.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
}
.text {
  font-size: 24px;
  margin: 30px 0;
  text-align: center;
  color: #222629;
}
.buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 5px 0;
}

.button_ok {
  width: 216px;
  height: 44px;
  color: #241F5A;
  border-color: #241F5A;
  font-weight: bold;
}

.button_ext {
  width: 216px;
  height: 44px;
  color: white;
  background-color: #241F5A;
  border-color: #241F5A;
  font-weight: bold;
}

.button_full {
  width: 100%;
  height: 44px;
  color: white;
  background-color: #241F5A;
  border-color: #241F5A;
  font-weight: bold;
}
