.wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(36, 31, 90, 0.78);
  z-index: 2;
  .window {
    position: relative;
    padding: 50px;
    border-radius: 8px;
    background-color: white;

    max-height: calc(100vh - 2rem);
    overflow: auto;

    // .closeIcon {
    //   position: absolute;
    //   right: 15px;
    //   top: 15px;
    //   width: 25px;
    //   height: 25px;
    //   cursor: pointer;
    // }
  }
  
}