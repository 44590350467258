.monthPicker {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: 210px;
    width: 300px;

    height: 56px;
    border: 1px solid #dddddd;
}
.selectYear {
    width: 80px !important;
    // height:54px;
    // border: 1px solid #dddddd;
    border: none;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    padding-left: 20px;
    background: url("../../../assets/icons/select-arrow.svg") no-repeat right;
    background-size: 15px 15px, 15px 15px, 1px 1.5em;
    // margin: 0 15px 0 15px;
    font-size: 17px;
    color: #241f5a;
    font-weight: 700;
    cursor: pointer;
}
.selectMonth {
    width: 110px !important;
    border: none;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    padding-left: 10px;
    background: url("../../../assets/icons/select-arrow.svg") no-repeat right;
    background-size: 15px 15px, 15px 15px, 1px 1.5em;
    font-size: 17px;
    color: #241f5a;
    font-weight: 700;
    cursor: pointer;
}
.buttonPrev {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(155, 155, 155, 0.46);
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}
.buttonPrevDisabled {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(155, 155, 155, 0.46);
    border-radius: 50%;
    margin-left: 10px;
}
.buttonNext {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(155, 155, 155, 0.46);
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}
.buttonNextDisabled {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(155, 155, 155, 0.46);
    border-radius: 50%;
    margin-right: 10px;
    margin-left: 10px;
}
.buttonPrevTransparent,
.buttonNextTransparent {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    margin-left: 10px;
}
.nextArrow {
    width: 18px;
}
