// .wrapper {
//   // display: flex;
//   // flex-direction: column;
//   // align-items: center;
//   // width: 440px;
// }
.content {
  display: flex;
  // overflow: auto;
  // height:520px;
}
.column {
  width: 400px;
  &:not(:first-child) {
    margin-left: 30px;
  }
}
.row {
  display: flex;
  justify-content: center;
}
.amountRow {

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.additionalSettingsRow {
  display: flex;
  align-items: center;
  height: 30px;
  cursor: pointer;
}
.text {
  margin-top: 30px;
  height: 56px;
  line-height: 56px;
  font-size: 16px;
  color: #9b9b9b;
}
.icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
}
.iconColor {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.iconColorActive {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 14px;
    top: 7px;
    width: 10px;
    height: 16px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.iconActive {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    left: 13px;
    top: 6px;
    width: 10px;
    height: 16px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.error {
  margin-bottom: 10px;
}
.bankName {
  height:34px;
}
.regularPayment {
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-top:10px;
  cursor:pointer;
}
.buttonCancel {
  margin-right: 20px;
  width: 190px;
  height: 44px;
  color: #241F5A;
  border-color: #241F5A;
  font-weight: bold;
}
.buttonSave {
    width: 190px;
    height: 44px;
    color: white;
    background-color: #241F5A;
    border-color: #241F5A;
    font-weight: bold;
}
