.wrapper {
  display: flex;
  justify-content: space-between;
  height: 32px;
  background-color: #222629;
  padding: 0 30px;
  & > div {
    display: flex;
    align-items: center;
    .item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      color: #c2c2c2;
      font-size: 14px;
      &:not(:first-child) {
        cursor: pointer;
      }
    }
  }
}
