.checkbox {
  width: 24px;
  height: 24px;
  position: relative;
  border: 1px solid #7abd43;
  border-radius: 4px;
  cursor: pointer;
  .checkmark {
    position: absolute;
    left: 6px;
    top: -1px;
    width: 10px;
    height: 16px;
    border: solid #7abd43;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
.slider {
  width: 48px;
  min-width: 48px;
  height: 24px;
  position: relative;
  border-radius: 12px;
  cursor: pointer;
  &Checked {
    background-color: #7abd43;
  }
  &Unchecked {
    background-color: #241f5a;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    transition: left 0.3s;
    &Checked {
      left: 26px;
    }
    &Unchecked {
      left: 2px;
    }
  }
}

.disabled {
  cursor: not-allowed;
}
