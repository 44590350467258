.depositOrder {
  min-height: 100%;
}
.header {
  padding: 30px;
  background-color: white;
}
// .title {
//   //margin-bottom: 30px;
// }
.content {
  //margin: 0 -10px;
  display: flex;
  padding: 0 20px;
  > div {
    flex: 1;
    padding: 0 10px;
  }
  background-color: white;
}
.body {
  display: flex;
}
.select,
.error {
  margin-bottom: 15px;
}
.warning {
  margin: 20px 30px;
  color: #ff7a7c;
}
.buttons {
  margin: 10px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  width: 200px;
  height: 48px;
  font-weight: bold;
  &Back {
    margin-right: 30px;
    color: #241f5a;
    text-decoration: underline;
  }
  &Submit {
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
  }
}

.products {
  padding: 30px;
  color: #222629;
}

.product {
  width: 100%;
  height: 140px;
  border-radius: 8px;
  box-shadow: 0.5px 3.5px 7.5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
}

.product_header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #E9F2DA;
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.product_header_wrapper {
  display: flex;
  padding: 10px;
  flex: 1;
}

.product_header_icon {
  margin-left: 10px;
  margin-right: 20px;
}

.product_header_name {
  display: flex;
  font-size: 1.4rem;
  align-items: center;
}

.product_header_percent {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  justify-content: center;

  div {
    text-align: right;
  }

  // div:first-child {

  // }

  div:last-child {
    font-size: 1.7rem;
    font-weight: bold;
  }
}

.product_footer {
  flex: 1;
  display: flex;
  padding: 0 20px 0 0;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 0 0 130px;
    width: 130px;
    display: flex;
    align-items: center;
    flex-direction: column;

    > div:last-child {
      line-height: 30px;
    }
  }

  > div:last-child {
    flex: 1;
    align-items: flex-end;

    > div:last-child {
      color: #7ABD43;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }
}
.termType {
  font-size: 14px;
}

