.wrapper {
  display: flex;
  height: 70px;
  background-color: white;

  &>div {
    &:nth-child(1) {
      display: flex;
      align-items: center;
      width: 272px;
      padding: 0 30px;

      &.logo {
        display: flex;
        justify-content: space-between;

        &>div {
          display: flex;

          &:nth-child(2) {
            height: 100%;
            color: #9b9b9b;
            font-size: 12px;
            align-items: center;
          }
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 752px;
      padding: 0 30px;
      
      .links {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 612px;
      }
      .logout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
  }
}