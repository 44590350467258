.wrapper {
    width: 800px;
}
.row {
    display: flex;
    margin: 15px 10px;
    width: 100%;
}
.name {
    color:#606060;
    font-size: 18px;
    width: 40%;
}
.value {
    font-size: 16px;
    width: 60%;
}
.rowLink {
    display:flex;
    justify-content: center;
}
.linkText {
    color: #9b9b9b;
    font-size: 12px;
}
.link {
    color: #241f5a;
    font-size: 12px;
    margin-left: 5px;
}
.buttonBlock {
    display:flex;
    justify-content: center;
    width: 100%;
    margin: 15px 0px;
}
.buttonCancel {
    margin-right: 20px;
    width: 190px;
    height: 44px;
    color: #241F5A;
    border-color: #241F5A;
    font-weight: bold;
  }
.buttonContinue {
    width: 190px;
    height: 44px;
    color: white;
    background-color: #241F5A;
    border-color: #241F5A;
    font-weight: bold;
}