.financialOperation {
  border-bottom: 1px solid #f2f2f2;
}
.row {
  display: flex;
  align-items: center;
  height: 56px;
}
.time {
  width: 60px;
  color: #9b9b9b;
  font-size: 14px;
}
.icon {
  margin: 12px;
  width: 32px;
  height: 32px;
  border: 1px solid #979797;
  border-radius: 50%;
}
.smallIcon {
  position:relative;
  z-index:2;
  margin-top:15px;
  margin-left:-20px;
  width: 15px;
  height: 15px;
}
.description {
  width: 410px;
  padding: 0 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #606060;
  font-size: 14px;
  overflow: hidden;
}
.amount {
  width: 120px;
  font-size: 16px;
  font-weight: 700;
  color: #241f5a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &Positive {
    color: #7abd43;
  }
  &Negative {
    color: #b1646e;
  }
}
.iconWrapper {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.details {
  padding: 20px 0;
}