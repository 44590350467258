.recieptWrapper {
    display: flex;
    justify-content: center;
}
.input {
    margin-bottom: 15px;
    height: 35px;
}
.button {
    font-family: Helvetica;
    font-weight: bold;
    color: #241f5a;
    border: 1px solid #241f5a;
    width: 242px;
    height: 35px;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
}
.buttonPDF {
    font-family: Helvetica;
    font-weight: bold;
    color: #241f5a;
    border: 1px solid #241f5a;
    width: 218px;
    height: 35px;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
}
.iconPDF {
    margin-right: 5px;
}
