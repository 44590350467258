.wrapper {
  position: relative;
  width: 114px;
  color: #c2c2c2;
  font-size: 14px;
  cursor: pointer;
  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    background-color: #222629;
  }
  .options {
    position: absolute;
    width: 100%;
    background-color: #222629;
    .option {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 10px;
    }
  }
}
