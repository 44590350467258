.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.wrapper {
  //height: 100%;
  //padding: 30px;
  background-color: white;
}

.content {
  margin: auto;
  width: 500px;
  padding-bottom: 20px;
}

.section {
  margin: 10px 0;
  padding: 10px 5px;
  background-color: white;

  table {
    padding-top: 10px;
    padding-bottom: 20px;

    thead > tr, tbody > tr {
      height: 50px;
    }

    th {
      color: gray;
      font-size: 14px;
      font-weight: normal;
      border-bottom: 1px solid lightgray;
      vertical-align: top;
    }

    th:not(:first-child) {
      text-align: right;
    }

    td {
      height: 45px;

      > div {
        height: 45px;
        margin-left: 5px;

        > input {
          min-width: 80px;
          text-align: right;
        }
      }
    }

    td:first-child {
      font-size: 14px;
    }

    th:not(:first-child), td:not(:first-child) {
      width: 90px;
    }

    td:not(:first-child) {
      text-align: right;
    }

  }
}

.error {
  margin-bottom: 20px;
}

.globalError {
  margin-bottom: 30px;
}

.quote {
  border: 1px solid lightgray;
  border-radius: 5px;
  color: gray;
  padding: 10px 20px;
  font-size: 15px;
}

.title {
  display: flex;
  padding: 10px 0;
  font-size: 20px;

  > img {
    width: 30px;
    height: 30px;
  }

  .name {
    display: flex;
    flex-grow: 1;
    font-weight: bold;
    align-items: center;
    margin-left: 15px;
  }

  .invoice {
    display: flex;
    flex: 1;
    max-width: 15%;
    justify-content: flex-end;
  }

  .paid {
    display: flex;
    flex: 1;
    max-width: 15%;
    justify-content: flex-end;
  }

  .control {
    display: flex;
    flex: 1;
    max-width: 15%;
    font-size: 16px;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.header {
  color: gray;
  padding: 10px 0;
  font-size: 16px;
  display: flex;

  .name {
    display: flex;
    flex-grow: 1;
    font-weight: bold;
    align-items: center;
    margin-left: 15px;
  }

  .invoice {
    display: flex;
    flex: 1;
    max-width: 15%;
    justify-content: flex-end;
  }

  .paid {
    display: flex;
    flex: 1;
    max-width: 15%;
    justify-content: flex-end;
  }

  .control {
    display: flex;
    flex: 1;
    max-width: 15%;
  }
}

.total {
  font-size: 26px;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;

  span {
    color: gray;
  }
}

.spinner {
  margin: 50px auto;
}
