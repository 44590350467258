.wrapper {
    width: 800px;
}
.row {
    display: flex;
    margin: 15px 10px;
    width: 100%;
}
.name {
    color:#606060;
    font-size: 18px;
    width: 40%;
}
.value {
    font-size: 16px;
    width: 60%;
}