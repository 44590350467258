.wrapper {
  min-height: 100%;
  padding: 30px;
  background-color: white;
}
.select {
  margin: 30px 0;
  width: 218px;
}
.locationDescription {
  margin: 30px auto;
}
.spinner {
  margin: 30px auto;
}
.error {
  margin: 30px 0;
}
