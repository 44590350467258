.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px 0;
  background-image: url("../../assets/images/auth-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
