.depositOrder {
  min-height: 100%;
  background-color: white;
}
.header {
  padding: 30px;
  background-color: white;
}
.content {
  margin: 30px auto 0;
  width: 500px;
}

.content_table {
  padding: 0 30px;
  width: 100%;

  table {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;

    tr {
      line-height: 1.5rem;

      td {
        padding: 0.2rem;
        border: 1px solid black;

        &:first-child {
          width: 60%;
        }

        &:last-child {
          width: 40%;
          vertical-align: top;
        }
      }
    }
  }
}

.body {
  display: flex;
}
.select,
.error {
  margin-bottom: 15px;
}
.buttons {
  margin: 10px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  width: 200px;
  height: 48px;
  font-weight: bold;
  &Back {
    margin-right: 30px;
    color: #241f5a;
    text-decoration: underline;
  }
  &Submit {
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
  }
}

.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin {
  margin-bottom: 20px;
}
