.category {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.top {
    padding: 30px;
}
.goBackBlock {
    display:flex;
}
.icon {
    cursor:pointer;
}
.title {
    margin-left: 15px;
    cursor:pointer;
}
.bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
}
.inputMonthBlock {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 20px;
}
.label {
    font-size: 14px;
    color: #606060;
    margin-bottom: 5px;
    margin-left:25px;
}
.statisticWrapper {
    margin-top: 30px;
}
.statisticBlock {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 12px 0 12px 0;
    padding:0 25px 0 25px;
    cursor:pointer;

    span {
        display: block;
        span{
            color:#9b9b9b;
        }
    }
}
.categoryIcon {
    width: 46px;
    height: 46px;
    margin-right: 10px;
}
.categoryName {
    display: flex;
    width: 38%;
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}
.localAmount {
    display:flex;
    justify-content: flex-end;
    align-items:flex-end;
    width:35%;
    font-size: 24px;
    font-weight: bold;
    span {
        display:block;
    }
}
.currency {
    color: #222629;
    font-size:12px;
    margin-left: 5px;
}
.operationsWrapper {
    //margin-top: 25px;
    padding: 0 25px;
}
.operationBlock {
    display: flex;
    align-items: center;
    border-top: 1px solid #f2f2f2;
    padding: 15px 0;
}
.operationDate{
    color:#9b9b9b;
    font-family: Helvetica;
    font-size: 14px;
}
.description {
    color: #606060;
    font-family: Helvetica;
    width: 385px;
}
.operationIcon {
    margin:0 15px 0 15px;
}

.operationImage {
    width: 32px;
    height: 32px;
    margin:0 15px 0 15px;
}

.operationAmount {
    display:flex;
    justify-content: flex-end;
    align-items:flex-end;
    width:35%;
    font-size: 16px;
    font-weight: bold;
    span {
        display:block;
    }
}
.operationCurrency {
    color:#606060;
    font-size: 12px;
    font-weight: bold;
    margin-left: 3px;
}
.pointsIcon {
    cursor: pointer;
}
