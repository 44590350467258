.filterBlock {
  display:flex;
  justify-content: space-between;
}
.historyFilter {
  display: flex;
  padding: 15px;
  & > div {
    width: 240px;
    padding: 0 15px;
  }
}
.blockPDF{
  display:flex;
  flex-direction: column;
}
.arrowPDF {
  // display:flex;
  // flex-direction: column;
  margin-top:50px;
  margin-right:25px;
  padding:5px;
}
.ractangleCopy {
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 2.5px;
  box-shadow: 1px 1px 3.5px 0 rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  border:none;
  cursor:pointer;
  & > img{
    display:block;
  }
}
.selectPDF{
  position: relative;
  z-index:10;
  background-color: #FFFFFF;
  border-radius: 2.5px;
  box-shadow: 1px 1px 3.5px 0 rgba(0, 0, 0, 0.1);
  padding:3px;
  cursor:pointer;
  top:-3px;
  right:30px;
}
