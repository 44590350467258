.wrapper {
    display: flex;
    flex-direction: column;
}
.text {
    font-size: 18px;
    margin: 15px 0px 15px 0px;
}
.buttons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 15px 0px 0px 0px;
}
.buttonCancel {
    margin-right: 20px;
    width: 190px;
    height: 44px;
    color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
}
.buttonDelete {
    width: 190px;
    height: 44px;
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
}