.feedback {
  width: 400px;
}
.title {
  text-align: center;
}
.text {
  margin: 30px 0 20px;
  font-size: 16px;
  color: #121212;
}
.input {
  margin-bottom: 20px;
}
.error {
  margin-bottom: 10px;
}
.button {
  margin: 20px auto 0;
  width: 216px;
  height: 44px;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
