.locationDescription {
  display: flex;
  flex-direction: column;
  width: 470px;
}
.title {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  color: #121212;
  font-size: 16px;
  font-weight: 700;
}
.content {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left {
  margin-right: 15px;
}
.address {
  margin: 5px 10px 5px 0;
  color: #212629;
  font-size: 15px;
}
.description {
  font-size: 13px;
  color: #9b9b9b;
}
.icon {
  cursor: pointer;
}
