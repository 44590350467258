.wrapper {
  position: relative;
  width: 934px;
  padding: 40px 52px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.languageSwitch {
  position: absolute;
  right: 20px;
  top: 15px;
}
.title {
  margin: 30px 0;
  text-align: center;
}
.columns {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 830px;
}
.column {
  width: 400px;
}
.input,
.select,
.error {
  margin-bottom: 15px;
}
.text {
  font-size: 12px;
  color: #9b9b9b;
}
.row {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.link {
  color: #241f5a;
}
.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  width: 200px;
  height: 48px;
  font-weight: bold;
}
.buttonBack {
  margin-right: 30px;
  color: #241f5a;
  text-decoration: underline;
}
.buttonSubmit {
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
}
.buttonInactive {
  color: white;
  background-color: #c2c2c2;
  border-color: #c2c2c2;
}
