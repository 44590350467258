.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.languageSwitch {
  position: absolute;
  right: 20px;
  top: 15px;
}
.title {
  margin: 30px 0;
  text-align: center;
}
.input {
  margin-bottom: 25px;
}
.error {
  margin-bottom: 15px;
}
.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button {
  width: 200px;
  height: 48px;
  font-weight: bold;
}
.buttonBack {
  color: #241f5a;
  text-decoration: underline;
}
.buttonSubmit {
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
}
