.wrapper {
  position: relative;
  height: 96px;
  border: 1px solid #dddddd;
  background-color: white;
  &.error {
    border-color: #ff7a7c;
  }
  &Focused {
    background-color: #eef4ff;
    border-color: #7abd43;
    box-shadow: 0 0 4px 1px rgba(132, 190, 63, 0.58);
    &.error {
      box-shadow: 0 0 4px 1px rgba(255, 122, 124, 0.58);
    }
  }
  textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    resize: none;
    background-color: transparent;
    &::placeholder {
      color: #9b9b9b;
    }
  }
  .indicator {
    position: absolute;
    right: 0;
    color: #606060;
    font-size: 14px;
    left: 0;
    display: flex;
    justify-content: space-between;
  }
}
.plug {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
}
