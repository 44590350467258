.wrapper {
  display: flex;
  padding: 10px;
  border: 1px solid #ff7a7c;
  background-color: #fff3f4;
  color: #ff7a7c;
}
.icon {
  flex-shrink: 0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 2px solid #ff7a7c;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
}
.text {
  min-height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

