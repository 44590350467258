.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: white;
}
.addTemplateButton {
  width: 242px;
  height: 40px;
  color: #222629;
  font-size: 14px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.content {
  position: relative;
  flex: 1;
}
.categories {
  padding: 30px 30px 50px;
}
.category {
  padding-bottom: 20px;
  margin-bottom: 20px;
  &:first-child {
    border-bottom: 1px dotted #c2c2c2;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.title {
  margin-bottom: 40px;
}
.templates {
  margin: auto;
  width: 610px;
  display: grid;
  grid-template-columns: 280px 280px;
  grid-gap: 50px;
}
.templateTileWrapper {
  position: relative;
  width: 280px;
  height: 130px;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.warning {
  color: #ff7a7c;
}
.showMoreButton {
  margin: 20px auto 0;
  width: 164px;
  height: 48px;
  color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
