.wrapper {
  width: 400px;
}
.subtitle {
  margin: 30px 0 5px;
  color: #121212;
  font-size: 16px;
}
.row {
  display: flex;
  justify-content: space-between;
}
.column {
  &:nth-child(1) {
    width: 218px;
  }
  &:nth-child(2) {
    width: 158px;
  }
}
.buttonsWrapper, .error {
  margin-top: 40px;
}
.button {
  width: 196px;
  height: 48px;
  &Cancel {
    color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
  }
  &Save {
    color: white;
    background-color: #241f5a;
    font-weight: bold;
  }
}
.text {
  margin-top: 20px;
  font-size: 14px;
  color: #222629;
  &Bold {
    font-weight: 700;
  }
}
.buttonWrapper {
  margin-top: 40px;
  & > * {
    margin: auto;
  }
}

.codeLabel, .codeError {
  margin-top: 15px;
}

.messageWrapper {
  color: #222629;
  font-size: 20px;
}