.contractHistory {
  display: flex;
  flex-direction: column;
}
.navigationTabs {
  border-bottom: 2px solid#F8F8F8;
}
.dailyOperations {
  margin: 20px 0;
}
.warning {
  margin: 20px 30px;
  color: #ff7a7c;
}
.error {
  margin: 20px 30px;
}
.spinner {
  margin: 30px auto;
}
.button {
  margin: 30px auto;
  width: 164px;
  height: 48px;
  color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
