.wrapper {
    height: 100%;
    padding: 30px;
    background-color: white;
}
.content {
    margin: 0 auto 0;
    width: 500px;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.amountBlock {
    display: flex;
    flex-direction: column;
}
.amountAndCommissionBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.next {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.error {
    margin-bottom: 20px;
}
.errorAmount {
    margin-top: 15px;
}
