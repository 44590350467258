.dateRange {
  position: relative;
  
  .plug {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: not-allowed;
  }

  .inputWrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding-left: 10px;
    border: 1px solid #dddddd;
    background-color: #fff;
    font-size: 16px;

    .calendarImg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

  }

  & >  .inputWrap {
    cursor: pointer;
  }

  .inputWrapActive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding-left: 10px;
    font-size: 16px;
    background-color: #EEF4FF;
    border: 1px solid #7ABD43;
    box-shadow: 0 0 4px 1px rgba(132, 190, 63, 0.58);
    .calendarImg {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 750px;
    height: 636px;
    background-color: #FFFFFF;
    box-shadow: 16px 16px 35px 0 rgba(0, 0, 0, 0.32);
    margin-top: 2px;
    position: fixed;
    left:  calc(-375px + 50%);
    top:  calc(-318px + 50%);
    z-index: 5;
    .cancelIconWrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .cancelInnerBlock {
        margin-right: 10px;

        &:hover{
          cursor: pointer;
        }
      }
    }
    .buttonsWrap {
      width: 750px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 665px;
        .button {
          width: 200px;
          height: 48px;
          margin: 10px;
        }
        .buttonSubmit {
          color: white;
          background-color: #241f5a;
          border-color: #241f5a;
        }
        .buttonInactive {
          color: white;
          background-color: #c2c2c2;
          border-color: #c2c2c2;
        }
      }
    }
    .dateWrapRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .DayPicker {
        .DayPicker-wrapper {
          .DayPicker-NavBar {
            .DayPicker-NavButton--prev {
              width: 30px;
              height: 30px;
              background-color: #9b9b9b;
              border-radius: 50%;
              background-image: url("../../../assets/icons/date-range-left-arrow.png");
              left: 10px;
            }
            .DayPicker-NavButton--next {
              width: 30px;
              height: 30px;
              background-color: #9b9b9b;
              border-radius: 50%;
              background-image: url("../../../assets/icons/date-range-right-arrow.png");
              right: 10px;
            }
          }
          .DayPicker-Months {
            .DayPicker-Month {
              display: flex;
              flex-direction: column;
              margin-top: 25px;
              .DayPicker-Caption {
                display: flex;
                flex-direction: row;
                justify-content: center;
                .selectYear {
                  width: 60px !important;
                  border: 0px;
                  background: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  overflow: hidden;
                  overflow: -moz-hidden-unscrollable;
                  padding-right: 1px;
                  background: url('../../../assets/icons/select-arrow.svg') no-repeat right;
                  background-size: 15px 15px, 15px 15px, 1px 1.5em;
                  margin: 0 15px 0 15px;
                  font-size: 17px;
                  color: #241F5A;
                  font-weight: 700;
                }
                .selectMonth {
                  width: 100px !important;
                  border: 0px;
                  background: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  overflow: hidden;
                  overflow: -moz-hidden-unscrollable;
                  padding-right: 1px;
                  background: url('../../../assets/icons/select-arrow.svg') no-repeat right;
                  background-size: 15px 15px, 15px 15px, 1px 1.5em;
                  margin: 0 15px 0 15px;
                  font-size: 17px;
                  color: #241F5A;
                  font-weight: 700;
                }
              }
              .DayPicker-Week {
                .DayPicker-Day {
                  padding: 0.9em 0.6em 0.9em 0.6em;
                  border-radius: 7%;
                }
                .DayPicker-Day--selected {
                  background-color: #E9F2DA;
                  color: #000;
                }
              }
            }
            height: 395px;
            border: 1px solid #aeaeae;
            .DayPicker-Weekdays {
              .DayPicker-WeekdaysRow{
                .DayPicker-Weekday {
                  padding: 0.8em;
                }
              }
            }
          }
        }
      }

      .dateWrap {
        display: flex;
        flex-direction: column;
        margin: 10px;
        width: 312px;
        .datepickerHeader {
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: #606060;
        }
        .inputWrap {
          display: flex;
          flex-direction: row;
          background-color: #fff;
          width: 311px;
          height: 50px;
          border: 1px solid #aeaeae;
          margin: 10px 0 10px 0;
          .calendarImg {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

}

