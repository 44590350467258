.history {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  padding: 30px;
}
.bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.navigationTabs {
  border-bottom: 2px solid#F8F8F8;
}
.dailyOperations {
  margin: 20px 0;
}
.warning {
  margin: 20px 30px;
  color: #ff7a7c;
}
.buttonRefresh {
  margin: 30px auto;
  width: 164px;
  height: 48px;
  color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
.spinner {
  margin: 30px auto;
}
.error {
  margin: 15px 30px;
}
