.suggestionTile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 690px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}
.content {
  width: 400px;
  padding: 0 30px;
  & > * {
    margin: 5px 0;
  }
}
.title {
  font-size: 16px;
  font-weight: 700;
  color: #222629;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.description {
  font-size: 14px;
  color: #999999;
}
.button {
  height: 38px;
  color: #241f5a;
  font-size: 14px;
  font-weight: 700;
  border-color: #241f5a;
}
