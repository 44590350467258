.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 410px;
  padding: 30px;
}
.buttons {
  margin: -5px;
  display: flex;
  flex-wrap: wrap;
}
.button {
  margin: 5px;
  height: 40px;
  padding: 0 10px;
  color: #222629;
  font-size: 16px;
  background-color: white;
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
}
.bottom {
  flex: 1;
  background-color: white;
}
.productDetails {
  margin: 0 30px;
}
