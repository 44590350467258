.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.languageSwitch {
  position: absolute;
  right: 20px;
  top: 15px;
}
.title {
  margin: 30px 0;
  text-align: center;
}
.input {
  margin-bottom: 25px;
}
.error {
  margin-bottom: 15px;
}
.text {
  font-size: 12px;
  color: #9b9b9b;
}
.button {
  margin: 30px auto 0;
  width: 270px;
  height: 48px;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
