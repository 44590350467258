.history {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.top {
    padding: 30px;
}
.bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0 20px;
}
.spinner {
    margin: 50px auto;
}
.warning {
    margin: 20px 30px;
    color: #ff7a7c;
}
.content {
    margin: 20px 0;
    padding: 0 30px;
}
.date {
    display: flex;
    align-items: center;
    height: 30px;
    color: #606060;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #f2f2f2;
}
.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #f2f2f2;
}
.time {
    width: 60px;
    color: #9b9b9b;
    font-size: 14px;
}
.icon {
    margin: 12px;
    width: 32px;
    height: 32px;
    border: 1px solid #979797;
    border-radius: 50%;
}
.smallIcon {
    position: relative;
    z-index: 2;
    margin-top: 15px;
    margin-left: -20px;
    width: 15px;
    height: 15px;
}
.purpose {
    width: 380px;
    padding: 0 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #606060;
    font-size: 14px;
    overflow: hidden;
}
.amount {
   width: 120px 
}
.iconWrapper {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.dropDownWrapper {
    display: flex;
    flex-direction: column;
    width: 190px;
    position: relative;
    z-index: 999;
    top: -15px;
    left: 500px;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 1px 1px 3.5px 0 rgba(0, 0, 0, 0.1);
}
.dropdownOption {
    display: flex;
    align-items: center;
    padding: 9px;
    color: #606060;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
}
.dropdownLastOption {
    display: flex;
    align-items: center;
    padding: 9px;
    color: #606060;
    cursor: pointer;
}
