.wrapper {
  height: 100%;
  padding: 30px;
  background-color: white;
}

.list {
  padding-top: 20px;
  padding-bottom: 20px;
}

.item {
  padding-bottom: 20px;
}

.link {
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  text-decoration: none;
}

.body {
  padding-top: 5px;
  text-align: justify;
  margin-bottom: 5px;
}

.date {
  text-align: right;
}

.pages {
  text-align: center;
}

.page {
  color: gray;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    color: black;
  }
}

.active {
  color: black;
  cursor: default;
  font-weight: bold;
}
