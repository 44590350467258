.wrapper {
  height: 100%;
  padding: 30px;
  .options {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 218px 218px 218px;
    grid-template-rows: 218px 218px;
    grid-gap: 18px;
    .option {
      background-color: #ffffff;
      border-radius: 5px;
      box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 140px;
      }
      .text {
        padding: 0 30px;
        text-align: center;
        color: #222629;
      }
      &:hover {
        .text {
          color: #7abd43;
        }
      }
    }
  }
}
