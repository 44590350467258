.footer {
  background-color: #222629;
  display: flex;
  justify-content: space-between;
  height: 160px;
  padding: 30px;
  .leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .bottomTextWrap {
      div {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: #c2c2c2;
        a {
          color: #f2f2f2;
        }
      }
    }
  }
  .rightBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    .bottomRow {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .socialIconsWrap {
        display: flex;
        flex-direction: row;
        div {
          margin: 10px;
        }
      }
      div {
        color: #c2c2c2;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}
.icon {
  cursor: pointer;
}

.flex {
  display: flex;
  color: #c2c2c2;
  font-size: 12px;
  align-items: center;
  > div {
    margin-right: 1rem;
  }
}