.cardOrder {
  min-height: 100%;
  padding: 30px;
  background-color: white;
}
.title {
  margin-bottom: 30px;
}
.content {
  margin: auto;
  width: 400px;
}
.select,
.error {
  margin-bottom: 15px;
}
.buttons {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  width: 200px;
  height: 48px;
  font-weight: bold;
  &Back {
    margin-right: 30px;
    color: #241f5a;
    text-decoration: underline;
  }
  &Submit {
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
  }
}
