.select {
  position: relative;
  height: 56px;
  font-size: 16px;
  border: 1px solid #dddddd;
  background-color: white;
  cursor: pointer;
  &Open {
    background-color: #eef4ff;
    border-color: #7abd43;
    box-shadow: 0 0 4px 1px rgba(132, 190, 63, 0.58);
  }
  &.error {
    border-color: #ff7a7c;
    box-shadow: 0 0 4px 1px rgba(255, 122, 124, 0.58);
  }
}
.selectedOptionWrapper {
  display: flex;
  height: 100%;
  padding-right: 10px;
}
.selectedOption {
  display: flex;
  align-items: center;
  width: calc(100% - 16px);
  height: 100%;
  padding: 0 10px;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
.options {
  z-index: 100;
  position: absolute;
  margin-top: 2px;
  width: 100%;
  max-height: 280px;
  box-shadow: 3px 3px 8px 0 rgba(12, 20, 64, 0.25);
  overflow-y: auto;
}
.option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 10px;
  background-color: white;
  border-bottom: 1px solid #f2f2f2;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #e9f2da;
  }
  & > *:not(:last-child) {
    margin-right: 10px;
  }
}
.noOptions {
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 10px;
  background-color: white;
  border-bottom: 1px solid #f2f2f2;
  color: #ff7a7c;
}
.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9b9b9b;
}
.plug {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: not-allowed;
}
