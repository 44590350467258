.wrapper {
  height: 100%;
  padding: 30px;
  background-color: white;
}

.content {
  margin: auto;
  width: 500px;
}

.form {
  padding: 30px;
  background-color: white;
}

.row {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.spinner {
  margin: 50px auto;
}
