.wrapper {
  height: 100%;
  padding: 30px;
  background-color: white;
}
.content {
  margin: 30px auto 0;
  width: 500px;
}
.row {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.text {
  height: 56px;
  line-height: 56px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error {
  margin-bottom: 20px;
}
.globalError {
  margin-bottom: 30px;
}
.conditionText {
  margin-bottom: 30px;
  font-size: 12px;
  color: #9b9b9b;
}
.link {
  color: #241f5a;
}