.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  background-color: #7ABD43;
  border-top-color: transparent !important;
  border-style: solid;
  border-radius: 50%;
  border-color: #241F5A;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
