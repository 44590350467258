.wrapper {
    height: 550px;
    overflow-y: auto;
}
.row {
    display: flex;
}
.column {
    width: 400px;
    margin-right: 20px;
    margin-left: 20px;
}
.bankName {
    height: 34px;
}
.periodBlock {
    border-top: 1px solid #f2f2f2;
    width: 400px;
    margin-right: 20px;
    margin-left: 20px;
}
.instantInputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.buttonBlock {
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 15px 20px;
}
.buttonCancel {
    margin-right: 20px;
    width: 190px;
    height: 44px;
    color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
}
.buttonCreate {
    width: 190px;
    height: 44px;
    color: white;
    background-color: #241f5a;
    border-color: #241f5a;
    font-weight: bold;
}
.error {
    margin-bottom: 10px;
}
.amountBlock {
    display: flex;
}
.commission {
    margin-left: 15px;
}
.errorAmount {
    width: 220px;
    margin-bottom: 10px;
}
