.wrapper {
  height: 100%;
  padding: 30px;
  background-color: white;
}
.content {
  margin: auto;
  width: 400px;
}
.wrapperAvatarIcons {
  display: flex;
  justify-content: center;
}
.inputFile{
  display:none
}
.tooltipAndAvatarBlock {
  display: flex;
  flex-direction: column;
}
.tooltip {
  width: 80px;
  padding: 4px;
  background-color: lightgray;
  text-align: center;
  border-radius: 4px;
  margin-left: 140px;
}
.triangle {
  border: 10px solid transparent;
  border-top: 10px solid lightgray;
  width: 10px;
  margin-left: 180px;
}
.avatarImage {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin-left: 190px;
  cursor: pointer;
}
.pencel {
  position: relative;
  z-index: 10;
  top: 100px;
  right: 20px;
  cursor: pointer;
}
.dropDownWrapper {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 45px;
  position: relative;
  z-index: 999;
  top: 100px;
  right: 22px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 1px 1px 3.5px 0 rgba(0, 0, 0, 0.1);
}
.dropdownOption {
  display: flex;
  align-items: center;
  padding: 9px;
  color: #606060;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
}
.subtitle {
  margin: 35px 0 10px;
  color: #121212;
  font-size: 16px;
  font-weight: 700;
}
.input,
.error {
  margin-bottom: 15px;
}
.text {
  font-size: 12px;
  color: #9b9b9b;
}
.buttonRow {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}
.button {
  width: 200px;
  height: 48px;
  font-weight: bold;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
}
