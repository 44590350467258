.dialog {
  width: 400px;
}
.title {
  text-align: center;
}
.text {
  margin: 30px 0 20px;
  font-size: 16px;
  color: #121212;
  white-space: pre-line;
  overflow: scroll;
  max-height: calc(100vh - 14rem);
}
.input {
  margin-bottom: 20px;
}
.error {
  margin-bottom: 10px;
}
.button {
  width: 216px;
  height: 44px;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
.buttonCancel {
  margin-right: 20px;
  width: 190px;
  height: 44px;
  color: #241F5A;
  border-color: #241F5A;
  font-weight: bold;
}
.row {
  display: flex;
}