.changeTitle {
  width: 400px;
  text-align: center;
}
.title {
  margin: 30px 0;
}
.text {
  margin: 30px 0;
  text-align: center;
  font-size: 14px;
  color: #9b9b9b;
}
.input, .error {
  margin-bottom: 30px;
}
.button {
  margin: auto;
  width: 216px;
  height: 44px;
  color: white;
  background-color: #241f5a;
  border-color: #241f5a;
  font-weight: bold;
}
